import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Box, Stack, Typography, Button } from '@mui/material';
import AgeApproveIcon from '../../../../assets/oropuroV2/ageApprove.webp'
import {useLanguage} from "../../../features/localisation/useLanguage";

const AgeVerificationDialog: React.FC = () => {
    const [isVerified, setIsVerified] = useState<boolean>(() => {
        return localStorage.getItem('ageVerified') === 'true';
    });
    const { languagePack: {pack: {common: lang}}} = useLanguage()

    const handleConfirm = () => {
        localStorage.setItem('ageVerified', 'true');
        setIsVerified(true);
    };

    if (isVerified) return null;

    return (
        <Dialog
            maxWidth='xs'
            open={!isVerified}
            aria-labelledby="age-verification-dialog"
            aria-describedby="age-verification-description"
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'center',
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Box
                        sx={{
                            height: 150,
                            aspectRatio: '1 / 1',
                            backgroundImage: `url(${AgeApproveIcon})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                        }}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {lang.ageConfirmationMessage}
                    </Typography>
                </Stack>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    sx={{ mt: 4 }}
                >
                    {lang.iConfirmBTN}
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default AgeVerificationDialog;
