import {Box, Stack} from "@mui/material";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { useUser } from "../../../features/user/useUser";
import { useSnackbar, VariantType } from "notistack";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { useLocation } from "react-router-dom";
import { SectionDivider } from "../section_devider/SectionDivider";
import { ShortGamesList } from "./ShortGamesList";
import { FullGamesList } from "../../games/FullGamesList";
import { useLanguage } from "../../../features/localisation/useLanguage";
import {getCurrentDomain} from "../../../config";

interface GamesProps {
    gameType?: number
    onProviderNameChanged?: (providerName: string | null) => void
}

export const Games: FC<GamesProps> = ({onProviderNameChanged = () => {}}) => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const location = useLocation()
    const currentPath = location.pathname
    const searchParams = new URLSearchParams(location.search)
    const providerName = searchParams.get('providerName') ?? undefined
    const { groupedGames, providers, isGameSessionLoading, startGameSession } = usePlayGames()
    const filteredGroupedGames = providerName !== undefined && groupedGames !== null ? groupedGames.filter(group => group.provider.provider_name === providerName) : groupedGames
    const prevProviderName = useRef<string | undefined>();
    const { token } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const domain = getCurrentDomain()

    // useEffect(() => {
    //     if (initLoad) {
    //         if (!Boolean(groupedGames)) {
    //             if (!Boolean(providers)) {
    //                 getProviders(() => {
    //                     getGames()
    //                 })
    //             } else {
    //                 getGames()
    //             }
    //         }
    //         setInitLoad(false)
    //     }
    // }, [ getGames, getProviders, groupedGames, initLoad, providers ])


    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const clickHandler = () => {
        return token ? (game: GameObj) => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {
            console.log('Login required')
            sendSnackBar('warning', 'Login required')
        }
    }

    useEffect(() => {
        if (providers !== null && providers.length > 0) {
            if (prevProviderName.current !== providerName) {
                if (providerName !== undefined) {
                    const provider = providers.find(provider => provider.provider_name === providerName)
                    if (provider) {
                        onProviderNameChanged(provider.title ?? provider.provider_name)
                    }
                } else {
                    onProviderNameChanged(null)
                }
                prevProviderName.current = providerName
            }
        }
    }, [ onProviderNameChanged, providerName, providers ])

    return <Stack>
        {providerName === undefined && groupedGames !== null && groupedGames.map(group => <Fragment key={group.provider.provider_name}>
            <SectionDivider
                link={`${currentPath}?providerName=${group.provider.provider_name}`}
                linkName={lang.seeAllBTN}
            >
                <Box display={'flex'} justifyContent={'center'} gap={1}>
                    <Box
                        sx={{
                            backgroundImage: `url(${domain}/cdn/provider_img/mini_sqr/${group.provider.provider_name}.png)`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '32px',
                            height: '32px',
                        }}
                    />
                    {group.provider.title}
                </Box>
            </SectionDivider>
            <ShortGamesList games={group.games.slice(0, 9)} onGameClick={clickHandler()}/>
        </Fragment>)}

        {providerName !== undefined && filteredGroupedGames !== null && filteredGroupedGames.length === 1 && <SectionDivider link={currentPath} linkName={lang.returnBackBTN}>
            <Box display={'flex'} justifyContent={'center'} gap={1}>
                <Box
                    sx={{
                        backgroundImage: `url(${domain}/cdn/provider_img/mini_sqr/${filteredGroupedGames[0].provider.provider_name}.png)`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '32px',
                        height: '32px',
                    }}
                />
                {filteredGroupedGames[0].provider.title}
            </Box>
        </SectionDivider>}

        {providerName !== undefined && filteredGroupedGames !== null && filteredGroupedGames.length === 1 && <FullGamesList games={filteredGroupedGames[0].games} onGameClick={clickHandler()}/>}
    </Stack>
}