import { SectionDivider } from "../section_devider/SectionDivider";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { Fragment, useEffect, useState } from "react";
import { ShortGamesList } from "./ShortGamesList";
import { useUser } from "../../../features/user/useUser";
import { useSnackbar, VariantType } from "notistack";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { useLanguage } from "../../../features/localisation/useLanguage";
import {Box} from "@mui/material";
import {getCurrentDomain} from "../../../config";

export const FirstGroupedGames = () => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const { getProviders, getGames, groupedGames, providers } = usePlayGames()
    // const [initLoad, setInitLoad] = useState<boolean>(true)
    const { token } = useUser();
    const { isGameSessionLoading, startGameSession } = usePlayGames();
    const { enqueueSnackbar } = useSnackbar();
    const domain = getCurrentDomain()

    // useEffect(() => {
    //     if (initLoad) {
    //         if (!Boolean(groupedGames)) {
    //             if (!Boolean(providers)) {
    //                 getProviders(() => {
    //                     getGames()
    //                 })
    //             } else {
    //                 getGames()
    //             }
    //         }
    //         setInitLoad(false)
    //     }
    // }, [getGames, getProviders, groupedGames, initLoad, providers])



    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const clickHandler = () => {
        return token ? (game: GameObj) => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {
            console.log('Login required')
            sendSnackBar('warning', 'Login required')
        }
    }

    return <>
        {groupedGames !== null && groupedGames.slice(0, 5).map(group => <Fragment key={group.provider.provider_name}>
            <SectionDivider link={`/games?providerName=${group.provider.provider_name}`} linkName={lang.seeAllBTN}>
                <Box display={'flex'} justifyContent={'center'} gap={1}>
                    <Box
                        sx={{
                            backgroundImage: `url(${domain}/cdn/provider_img/mini_sqr/${group.provider.provider_name}.png)`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: '32px',
                            height: '32px',
                        }}
                    />
                    {group.provider.title}
                </Box>
            </SectionDivider>
            <ShortGamesList games={group.games.slice(0, 9)} onGameClick={clickHandler()}/>
        </Fragment>)}
    </>;
};