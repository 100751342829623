import Stack from "@mui/material/Stack";
import { FC, ReactNode } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Link } from "react-router-dom";

// gradient left to right DBB064 100% opacity to AE7E42 0 opacity
interface SectionDividerProps {
    children?: ReactNode
    onClick?: () => void
    buttonName?: string
    link?: string
    linkName?: string
    mt?: string | number
}

export const SectionDivider: FC<SectionDividerProps> = ({ children, onClick, buttonName, link, linkName, mt }) => {
    const { xxSmallScreen } = useCommonMediaQueries()

    const buttonSX = {
        textTransform: 'none',
        color: 'grey.400',
        borderWidth: '1px',
            borderColor: 'grey.800',
            '&:hover': {
            borderWidth: '1px',
                borderColor: 'grey.800',
        },
    }

    return <Stack mt={mt} direction={'row'} alignItems={'center'} justifyContent={xxSmallScreen ? 'space-between' : 'start'} gap={'15px'} py={xxSmallScreen ? '4px' : '15px'} width={xxSmallScreen ? '100%' : 'auto'}>
        {!xxSmallScreen && <Box height={'2px'} sx={{ background: 'linear-gradient(90deg, #AE7E4200, #DBB064ff)' }} flexGrow={1}/>}
        <Typography noWrap={true} fontSize={'20px'} textTransform={'uppercase'} fontWeight={500} sx={{ userSelect: 'none', cursor: onClick ? 'pointer' : 'default'}} onClick={onClick}>
            {!xxSmallScreen && link && linkName && <Tooltip title={linkName}><Link to={link}>{children}</Link></Tooltip>}
            {(xxSmallScreen || !link) && children}
        </Typography>
        { xxSmallScreen && link && linkName && !onClick && <Link to={link}><Button variant={'outlined'} size={'small'} sx={buttonSX}>{linkName}</Button></Link>}
        { xxSmallScreen && onClick && buttonName && !link && <Button onClick={onClick} variant={'outlined'} size={'small'} sx={buttonSX}>
            {buttonName}
        </Button>}
        {!xxSmallScreen && <Box height={'2px'} sx={{ background: 'linear-gradient(90deg, #DBB064ff, #AE7E4200)' }} flexGrow={1}/>}
    </Stack>
}