import { FC } from "react";
import { Stack } from "@mui/material";
import { theme } from "../../../themes/oropuro_V2/oroPuroV2_theme";
import { BottomMobileMenuItem } from "./BottomMobileMenuItem";
import HomeIcon from "../svg/HomeIcon";
import SportIcon from "../svg/SportIcon";
import PromotionsIcon from "../svg/PromotionsIcon";
import CasinoIcon from "../svg/CasinoIcon";
import MenuIcon from "../svg/MenuIcon";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const BottomMobileMenu: FC = () => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}}}} = useLanguage()

    return <Stack direction={'row'} sx={{backgroundColor: theme.palette.surface.main}} padding={'4px'} width={'100%'} gap={'3px'}>
        <BottomMobileMenuItem IconComponent={HomeIcon} link={'/'}>
            {menuLang.home}
        </BottomMobileMenuItem>
        <BottomMobileMenuItem IconComponent={PromotionsIcon} link={'/slots'}>
            {menuLang.slots}
        </BottomMobileMenuItem>
        <BottomMobileMenuItem IconComponent={CasinoIcon} link={'/live_casino'}>
            {menuLang.casino}
        </BottomMobileMenuItem>
        <BottomMobileMenuItem IconComponent={SportIcon} link={'/sport'}>
            {menuLang.sport}
        </BottomMobileMenuItem>
        <BottomMobileMenuItem IconComponent={MenuIcon} link={'/dashboard'}>
            {menuLang.menu}
        </BottomMobileMenuItem>
    </Stack>
}