import React, { FC } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CarouselSlide } from "./CarouselSlide";
import { Box, Stack } from "@mui/material";
import { CarouselSlideGradientTitle } from "./CarouselSlideGradientTitle";
import { CarouselSlideSecondaryTitle } from "./CarouselSlideSecondaryTitle";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../../features/localisation/useLanguage";

import Ezugi2Logo from '../../../../assets/oropuroV2/carousel_slides/providers_logo_for_slider/Ezugi1.webp'
import DigitainLogo from '../../../../assets/oropuroV2/carousel_slides/providers_logo_for_slider/digitain_logo.webp'

import { Link } from 'react-router-dom';

import CasinoGirl from '../../../../assets/oropuroV2/carousel_slides/casinoGirl.webp'
import CasinoGirl2x from '../../../../assets/oropuroV2/carousel_slides/casinoGirl@2x.webp'
import CasinoSlideBg from '../../../../assets/oropuroV2/carousel_slides/casino_bg.webp'

import SweetBg from '../../../../assets/oropuroV2/carousel_slides/sweet_bg.webp'
import Donut from '../../../../assets/oropuroV2/carousel_slides/donut_.webp'
import Donut2x from '../../../../assets/oropuroV2/carousel_slides/donut_@2x.webp'

import RacingBG from '../../../../assets/oropuroV2/carousel_slides/racing_bg.webp'
import Rider from '../../../../assets/oropuroV2/carousel_slides/rider.webp'
import Rider2x from '../../../../assets/oropuroV2/carousel_slides/rider@2x.webp'

import LiveCasinoHostes from '../../../../assets/oropuroV2/carousel_slides/live_casino_hostes.webp'
import LiveCasinoHostes2x from '../../../../assets/oropuroV2/carousel_slides/live_casino_hostes@2x.webp'
import LiveCasinoStudioBg from '../../../../assets/oropuroV2/carousel_slides/live_casino_studio_bg.webp'

export const DemoCarousel: FC = () => {
    const { languagePack: {pack: {argentina: {carousel: lang}}}} = useLanguage()
    const { xxSmallScreen, smallScreen, mediumScreen } = useCommonMediaQueries()
    const logoSize = xxSmallScreen ? 0.65 : smallScreen ? 0.95 : mediumScreen ? 1.2 : 1.6

    return <Carousel
        showThumbs={false}
        autoPlay={true}
        // autoPlay={false}
        infiniteLoop={true}
        showStatus={false}
        interval={6000}>

        <CarouselSlide bgImage={SweetBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? Donut : Donut2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                {/*<CarouselSlideSecondaryTitle color={'#de2667'}>*/}
                {/*    {lang.slide4.header.split('<br/>')[0]}*/}
                {/*</CarouselSlideSecondaryTitle>*/}
                <CarouselSlideGradientTitle gradient={'linear-gradient(to right, #de2667, #72042a)'}>
                    {lang.slide4.header}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle color={'#de2667'}>
                    {lang.slide4.tagline}
                </CarouselSlideSecondaryTitle>
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={RacingBG} backgroundPosition={'center 70%'}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? Rider : Rider2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle color={'#ad7249'}>
                    {lang.slide2.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle>
                    {lang.slide2.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle color={'#ad7249'}>
                    {lang.slide2.tagline}
                </CarouselSlideSecondaryTitle>
                <Box
                    width={`${logoSize * 116}px`}
                    height={`${logoSize * 20}px`}
                    sx={{
                        // backgroundColor: '#c42424',
                        backgroundImage: `url(${DigitainLogo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={LiveCasinoStudioBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? LiveCasinoHostes : LiveCasinoHostes2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle color={'#ffcd07'} textShadow={'0px 2px 10px black'}>
                    {lang.slide1.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle gradient={'linear-gradient(to left, #FFCC48, #fff4d2, #ffa500)'}>
                    {lang.slide1.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle
                    color={'#fff4d2'}
                    textShadow={`2px 3px 10px black`}>
                    {lang.slide1.tagline}
                </CarouselSlideSecondaryTitle>
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={CasinoSlideBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? CasinoGirl : CasinoGirl2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle>
                    {lang.slide5.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle>
                    {lang.slide5.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle>
                    {lang.slide5.tagline}
                </CarouselSlideSecondaryTitle>
                <Link to={'/games?providerName=Ezugi'}>
                    <Box
                    width={`${logoSize * 140}px`}
                    height={`${logoSize * 34}px`}
                    sx={{
                        // backgroundColor: '#c42424',
                        backgroundImage: `url(${Ezugi2Logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                </Link>
            </Stack>
        </CarouselSlide>


        {/*<CarouselSlide bgImage={CaveSlideBg}>*/}
        {/*    <Box*/}
        {/*        flex={2}*/}
        {/*        sx={{*/}
        {/*            backgroundImage: `url(${xxSmallScreen ? CaveGrandpa : CaveGrandpa2x})`,*/}
        {/*            backgroundSize: '100%',*/}
        {/*            backgroundPosition: 'top center',*/}
        {/*            backgroundRepeat: 'no-repeat',*/}
        {/*        }}/>*/}
        {/*    <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>*/}
        {/*        <CarouselSlideSecondaryTitle>*/}
        {/*            {lang.slide3.header.split('<br/>')[0]}*/}
        {/*        </CarouselSlideSecondaryTitle>*/}
        {/*        <CarouselSlideGradientTitle>*/}
        {/*            {lang.slide3.header.split('<br/>')[1]}*/}
        {/*        </CarouselSlideGradientTitle>*/}
        {/*        <CarouselSlideSecondaryTitle>*/}
        {/*            {lang.slide3.tagline}*/}
        {/*        </CarouselSlideSecondaryTitle>*/}
        {/*        <Stack*/}
        {/*            alignSelf={'start'}*/}
        {/*            direction={'row'}*/}
        {/*            width={`${300 * logoSize}px`}*/}
        {/*            justifyContent={'center'}*/}
        {/*            alignItems={'center'}*/}
        {/*            flexWrap={'wrap'}*/}
        {/*            // sx={{backgroundColor: '#d01f1f'}}*/}
        {/*        >*/}
        {/*            <img alt={'Tv Bet Logo'} src={TVBetLogo} height={`${40 * logoSize}px`} width={`${96 * logoSize}px`} style={{maxWidth: `${96 * logoSize}px`}}/>*/}
        {/*            <img alt={'XPro Gaming Logo'} src={XPGLogo} height={`${40 * logoSize}px`} width={`${79 * logoSize}px`} style={{maxWidth: `${79 * logoSize}px`}}/>*/}
        {/*            <img alt={'Pragmatic play Logo'} src={PragmaticLogo} height={`${40 * logoSize}px`} width={`${106 * logoSize}px`} style={{maxWidth: `${106 * logoSize}px`}}/>*/}
        {/*            <img alt={'Ezugi Logo'} src={Ezugi2Logo} height={`${40 * logoSize}px`} width={`${85 * logoSize}px`} style={{maxWidth: `${85 * logoSize}px`}}/>*/}
        {/*            <img alt={'Vivo gaming Logo'} src={VivoLogo} height={`${40 * logoSize}px`} width={`${128 * logoSize}px`} style={{maxWidth: `${128 * logoSize}px`}}/>*/}
        {/*        </Stack>*/}
        {/*    </Stack>*/}
        {/*</CarouselSlide>*/}
    </Carousel>
};