import { FC, ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface CarouselSlideGradientTitleProps {
    children?: ReactNode
    gradient?: string
}

export const  CarouselSlideGradientTitle: FC<CarouselSlideGradientTitleProps> = ({children, gradient}) => {
    const { smallScreen, portraitMode } = useCommonMediaQueries()

    return <Typography
        textTransform={'uppercase'}
        lineHeight={1}
        fontSize={smallScreen || portraitMode ? '6vw' : '6vh'}
        textAlign={'left'}
        fontWeight={700}
        sx={{
            background: gradient ?? 'linear-gradient(to bottom, #FFCC48, #FF6B00)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            userSelect: 'none'
        }}
    >
        {children}
    </Typography>
}