import Stack from "@mui/material/Stack";
import { FC } from "react";
import { Box } from "@mui/material";
import { theme } from "../../../themes/oropuro_V2/oroPuroV2_theme";

export const HeaderDecorationBottom: FC = () => {
    return <Stack direction={'row'} height={'2px'} justifyContent={'space-between'}>
        <SideDecorativeBlock left={true}/>
        <MiddleDecorativeBlock/>
        <SideDecorativeBlock left={false}/>
    </Stack>
}

interface SideDecorativeBlockProps {
    left: boolean
}

const SideDecorativeBlock: FC<SideDecorativeBlockProps> = ({left}) => {
    return <Box
        display={'flex'}
        justifyContent={left ? 'start' : 'end'}
        width={'10vw'}
        sx={{backgroundImage: `linear-gradient(to ${left ? 'right' : 'left'}, #E1BF7D, #AE7E4200)`}}
        px={'3vw'}
    >
        <DiamondShape size={'small'}/>
    </Box>
}

const MiddleDecorativeBlock: FC = () => {
    return <Box
        display={'flex'}
        justifyContent={'space-evenly'}
        width={'22vw'}
        sx={{backgroundImage: 'linear-gradient(to right, #AE7E4200 0%, #E1BF7D 50%, #AE7E4200 100%)'}}
        px={'3vw'}
    >
        <DiamondShape size={'small'}/>
        <DiamondShape size={'big'}/>
        <DiamondShape size={'small'}/>
    </Box>
}

interface DiamondShapeProps {
    size: 'small' | 'big'
}

const DiamondShape: FC<DiamondShapeProps> = ({size}) => <Box
    position={'relative'}
    mt={size === 'big' ? '-7px' : '-5px'}
    height={size === 'big' ? '16px' : '12px'}
    width={size === 'big' ? '16px' : '12px'}
    zIndex={999}
    sx={{
        backgroundColor: '#22242A',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        transform: 'rotate(45deg)'
    }}
/>